<template>
    <div>
        <div>
            <v-alert
                v-if="!signed || !authorized"
                border="top"
                color="accent"
                dark
                icon="mdi-draw"
                prominent
                >{{ $t("applications.online-app-authorization") }}</v-alert
            >
            <v-alert
                v-if="authorized"
                border="top"
                dark
                color="success darken-2"
                icon="mdi-clipboard-check"
                prominent
                >{{ $t("applications.application-is-authorized") }}</v-alert
            >
            <v-alert
                v-if="signed"
                border="top"
                dark
                color="success darken-2"
                icon="mdi-clipboard-check"
                prominent
                >{{ $t("applications.application-is-signed") }}</v-alert
            >
            <v-row>
                <v-col cols="12" class="my-0 d-flex">
                    <v-btn
                        color="secondary"
                        :dark="!authorized"
                        :disabled="authorized"
                        @click.stop="authorizeDialog = true"
                        ><v-icon class="mr-1">mdi-account-check</v-icon
                        >{{ $t("applications.view-and-authorized") }}</v-btn
                    >
                    <v-btn
                        color="secondary"
                        class="ml-4"
                        :dark="!signed"
                        :disabled="signed"
                        @click.stop="signatureDialog = true"
                        ><v-icon class="mr-1">mdi-draw</v-icon
                        >{{ $t("applications.sign-application") }}</v-btn
                    >
                    <v-spacer></v-spacer>
                    <v-btn
                        v-if="clearable"
                        @click.prevent="clearAuthorization"
                        color="warning darken-2"
                    >
                        <v-icon :small="$vuetify.breakpoint.smAndDown"
                            >mdi-close</v-icon
                        >Clear
                    </v-btn>
                </v-col>
            </v-row>
        </div>

        <v-dialog v-model="authorizeDialog" max-width="600">
            <applicant-authorize-policy
                v-if="authorizeDialog"
                @close="authorizeDialog = false"
                @accept="accept"
            ></applicant-authorize-policy>
        </v-dialog>

        <v-dialog v-model="signatureDialog" max-width="600">
            <applicant-sign
                v-if="signatureDialog"
                @close="signatureDialog = false"
                @save="save"
            ></applicant-sign>
        </v-dialog>
    </div>
</template>
<script>
// import FormSignature from "../Form/FormSignature.vue";
import api from "@components/application/api";

import { mapActions } from "vuex";

export default {
    name: "applicant-self-authorize",
    components: {
        ApplicantAuthorizePolicy: () => import("./ApplicantAuthorizePolicy"),
        ApplicantSign: () => import("./ApplicantSign"),
    },
    props: {
        applicant: {
            type: Object,
            required: true,
        },
        nextStepDisabled: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            option: {
                penColor: "rgb(0, 0, 0)",
                backgroundColor: "rgb(255,255,255)",
            },
            authorizationData: {},
            authorizeDialog: false,
            signatureDialog: false,
        };
    },
    watch: {
        agree(value) {
            // We have agreed to authorization
            this.authorizationData.application_authorization_agree = value;
        },
        authorizationData: {
            immediate: true,
            deep: true,
            handler(authorizationData) {
                this.$emit(
                    "update:nextStepDisabled",
                    this.$_.get(authorizationData, "file.key", []).length ===
                        0 ||
                        !this.$_.get(
                            authorizationData,
                            "application_authorization_agree",
                            false
                        )
                );
            },
        },
    },
    computed: {
        authorized() {
            return this.authorizationData.application_authorization_agree;
        },
        signed() {
            return this.authorizationData.file.key.length > 0;
        },
        clearable() {
            return this.applicant.sections.clearable;
        },
    },
    mounted() {},
    created() {
        this.authorizationData = this.applicant.authorizations;
    },
    updated() {},
    methods: {
        ...mapActions("Applications", ["updateWithoutReload"]),
        accept() {
            this.authorizationData.application_authorization_agree = true;
            this.authorizeDialog = false;
            this.updateApplicant();
        },
        async save(image) {
            try {
                this.signatureDialog = false;
                const data = await api.signApplication(
                    this.applicant.applicant_id,
                    image
                );
                if (data.status == "success") {
                    this.authorizationData.file = {
                        bucket: data.bucket,
                        key: data.path,
                    };
                    this.updateApplicant();
                }
            } catch (error) {
                // TODO: Handle Signature Error
                console.log(error);
            }
        },
        clearAuthorization() {
            this.authorizationData.application_authorization_agree = false;
            this.authorizationData.file = {
                bucket: "",
                key: "",
            };
            this.updateApplicant();
        },
        updateApplicant() {
            this.updateWithoutReload({
                authorizations: this.authorizationData,
            });
        },
    },
};
</script>

<style scoped>
</style>
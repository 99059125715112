import { render, staticRenderFns } from "./ApplicantSelfAuthorize.vue?vue&type=template&id=5f7d07d6&scoped=true&"
import script from "./ApplicantSelfAuthorize.vue?vue&type=script&lang=js&"
export * from "./ApplicantSelfAuthorize.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f7d07d6",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAlert,VBtn,VCol,VDialog,VIcon,VRow,VSpacer})


/* hot reload */
if (module.hot) {
  var api = require("/home/circleci/project/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5f7d07d6')) {
      api.createRecord('5f7d07d6', component.options)
    } else {
      api.reload('5f7d07d6', component.options)
    }
    module.hot.accept("./ApplicantSelfAuthorize.vue?vue&type=template&id=5f7d07d6&scoped=true&", function () {
      api.rerender('5f7d07d6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/application/components/Apply/ApplicantSelfAuthorize.vue"
export default component.exports
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        [
          !_vm.signed || !_vm.authorized
            ? _c(
                "v-alert",
                {
                  attrs: {
                    border: "top",
                    color: "accent",
                    dark: "",
                    icon: "mdi-draw",
                    prominent: ""
                  }
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("applications.online-app-authorization"))
                  )
                ]
              )
            : _vm._e(),
          _vm.authorized
            ? _c(
                "v-alert",
                {
                  attrs: {
                    border: "top",
                    dark: "",
                    color: "success darken-2",
                    icon: "mdi-clipboard-check",
                    prominent: ""
                  }
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("applications.application-is-authorized"))
                  )
                ]
              )
            : _vm._e(),
          _vm.signed
            ? _c(
                "v-alert",
                {
                  attrs: {
                    border: "top",
                    dark: "",
                    color: "success darken-2",
                    icon: "mdi-clipboard-check",
                    prominent: ""
                  }
                },
                [_vm._v(_vm._s(_vm.$t("applications.application-is-signed")))]
              )
            : _vm._e(),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "my-0 d-flex", attrs: { cols: "12" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "secondary",
                        dark: !_vm.authorized,
                        disabled: _vm.authorized
                      },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          _vm.authorizeDialog = true
                        }
                      }
                    },
                    [
                      _c("v-icon", { staticClass: "mr-1" }, [
                        _vm._v("mdi-account-check")
                      ]),
                      _vm._v(_vm._s(_vm.$t("applications.view-and-authorized")))
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "ml-4",
                      attrs: {
                        color: "secondary",
                        dark: !_vm.signed,
                        disabled: _vm.signed
                      },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          _vm.signatureDialog = true
                        }
                      }
                    },
                    [
                      _c("v-icon", { staticClass: "mr-1" }, [
                        _vm._v("mdi-draw")
                      ]),
                      _vm._v(_vm._s(_vm.$t("applications.sign-application")))
                    ],
                    1
                  ),
                  _c("v-spacer"),
                  _vm.clearable
                    ? _c(
                        "v-btn",
                        {
                          attrs: { color: "warning darken-2" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.clearAuthorization($event)
                            }
                          }
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              attrs: {
                                small: _vm.$vuetify.breakpoint.smAndDown
                              }
                            },
                            [_vm._v("mdi-close")]
                          ),
                          _vm._v("Clear ")
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "600" },
          model: {
            value: _vm.authorizeDialog,
            callback: function($$v) {
              _vm.authorizeDialog = $$v
            },
            expression: "authorizeDialog"
          }
        },
        [
          _vm.authorizeDialog
            ? _c("applicant-authorize-policy", {
                on: {
                  close: function($event) {
                    _vm.authorizeDialog = false
                  },
                  accept: _vm.accept
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "600" },
          model: {
            value: _vm.signatureDialog,
            callback: function($$v) {
              _vm.signatureDialog = $$v
            },
            expression: "signatureDialog"
          }
        },
        [
          _vm.signatureDialog
            ? _c("applicant-sign", {
                on: {
                  close: function($event) {
                    _vm.signatureDialog = false
                  },
                  save: _vm.save
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }